import dayjs, { Dayjs } from "dayjs";

export interface PrereleaseCreateForm {
    description: string,
    validUntil: Dayjs,
}

export const EmptyPrereleaseCreateForm: PrereleaseCreateForm = {
    description: "",
    validUntil: dayjs().add(14, 'day'),
} ;