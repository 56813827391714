import { BlobServiceClient, BlobUploadCommonResponse, BlockBlobParallelUploadOptions, BlockBlobTier, ContainerCreateResponse } from '@azure/storage-blob'

export interface TransferProgressEventAdapted {
    loadedBytes: number
}

export class AzureBlobStorageFileService {

    private BlobServiceClient: BlobServiceClient;

    constructor(connectionString: string) {
        this.BlobServiceClient = new BlobServiceClient(connectionString);
    }

    async CreateBlobContainer(containerName: string): Promise<ContainerCreateResponse> {
        const containerClient = this.BlobServiceClient.getContainerClient(containerName);
        const createContainerResponse = await containerClient.create();
        return createContainerResponse;
    }

    async UploadFileToBlobContainer(
        containerName: string,
        file: ArrayBuffer,
        fileName: string,
        onProgress?: (props: TransferProgressEventAdapted) => void): Promise<BlobUploadCommonResponse | undefined> {
        const containerClient = this.BlobServiceClient.getContainerClient(containerName);
        try {
            const createContainerResponse = await containerClient.create();

            const blockBlobClient = containerClient.getBlockBlobClient(fileName);

            const uploadBlobResponse = await blockBlobClient.uploadData(file,
                {
                    blockSize: 4 * 1024 * 1024,
                    concurrency: 20,
                    tier:BlockBlobTier.Cool,
                    onProgress: (props) => {
                        if (onProgress) {
                            onProgress({ loadedBytes: props.loadedBytes } as TransferProgressEventAdapted)
                        }
                    }
                } as BlockBlobParallelUploadOptions);

            return uploadBlobResponse;
        }
        catch (error) {
            console.log(error)
        }
    }
}
